<template>
  <div class="card-form">
    <!-- Card  -->
    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :isCardNumberMasked="isCardNumberMasked"
        :randomBackgrounds="randomBackgrounds"
        :backgroundImage="backgroundImage"
      />
    </div>
    <!-- Card -->

    <!-- Initial Card Form -->
    <div class="card-form__inner">
      <div class="card-input">
        <label for="cardNumber" class="card-input__label">Card Number</label>
        <input
          required
          type="tel"
          :id="fields.cardNumber"
          @input="changeNumber"
          @focus="focusCardNumber"
          @blur="blurCardNumber"
          class="card-input__input"
          :value="formData.cardNumber"
          :maxlength="cardNumberMaxLength"
          data-card-field
          autocomplete="off"
        />
        <button
          class="card-input__eye"
          :class="{ '-active' : !isCardNumberMasked }"
          title="Show/Hide card number"
          tabindex="-1"
          :disabled="formData.cardNumber === ''"
          @click="toggleMask"
        ></button>
      </div>
      <div class="card-input">
        <label for="cardName" class="card-input__label">Card Holder</label>
        <input
          required
          type="text"
          :id="fields.cardName"
          v-letter-only
          @input="changeName"
          class="card-input__input"
          :value="formData.cardName"
          data-card-field
          autocomplete="off"
        />
      </div>
      <div class="card-form__row card-input">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">Expiration Date</label>
            <select
              required
              class="card-input__input -select"
              :id="fields.cardMonth"
              v-model="formData.cardMonth"
              @change="changeMonth"
              data-card-field
            >
              <option value disabled selected>Month</option>
              <option
                v-bind:value="n < 10 ? '0' + n : n"
                v-for="n in 12"
                v-bind:disabled="n < minCardMonth"
                v-bind:key="n"
              >{{generateMonthValue(n)}}</option>
            </select>
            <select
              required
              class="card-input__input -select"
              :id="fields.cardYear"
              v-model="formData.cardYear"
              @change="changeYear"
              data-card-field
            >
              <option value disabled selected>Year</option>
              <option
                v-bind:value="$index + minCardYear"
                v-for="(n, $index) in 12"
                v-bind:key="n"
              >{{$index + minCardYear}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-input">
        <label for="amount" class="card-input__label">Amount:</label>
        <b-form-input
          disabled
          type="text"
          id="amount"
          class="card-input__input"
          :value="dataOrderPay.firstpay"
          autocomplete="off"
        />
      </div>
      <b-row>
        <b-col md="6">
          <b-button 
            class="card-form__button"
            variant="primary"
            :disabled="enableButton || isLoadingUpdate"
            v-on:click="sendCard()"
            >
            <div v-if="!isLoadingUpdate">
              Send Payment
            </div>
            <div v-else>
              <b-spinner small></b-spinner>
              <b-spinner small variant="primary" label="Small Spinner"></b-spinner>
            </div>
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button 
            class="card-form__button text-center"
            variant="warning"
            :disabled="isLoadingUpdate"
            v-on:click="resetForm()"
            > Reset
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <div>
        <label style="justify-content: center">
          CREDIT CARD DISCLAIMER
          <br>
          <br>
          Your security is important to us. We do not store your credit card information. Online payments are passed via a secure socket layer to a payment processor where your information is tokenized (whereby a random number is generated to represent your payment).<br> The payment processor is PCI compliant which ensures that your information is being handled in accordance with industry security standards.
        </label>
      </div>
    </div>
    <!-- Initial Card Form -->
  </div>
</template>

<script>
import Card from '@/modules/shop/components/landing/Card'
import { mapState, mapMutations, mapActions } from "vuex"
import { showAlertMessage } from '../../../../helpers/helpers'
import { validCard } from '../../../../helpers/shopHelper'
import { cardImages, cardTypes, DEFAULT_CARD_FORMAT, DEFAULT_ZIP_LENGTH, DEFAULT_CVC_LENGTH } from "@/data/card"

export default {
  name: 'dataOrderPay.cardForm',
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) return true
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) event.preventDefault()
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: '',
          cardNumber: '',
          cardNumberNotMask: '',
          cardMonth: '',
          cardYear: '',
          // cardCvv: ''
        }
      }
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true
    },
    FormStatus: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Card
  },
  data () {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        // cardCvv: 'v-card-cvv'
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
      cardImages,
      cardTypes,
      DEFAULT_CARD_FORMAT,
      DEFAULT_ZIP_LENGTH,
      DEFAULT_CVC_LENGTH,
      isLoadingUpdate: false,
    }
  },
  computed: {
    ...mapState("shop", ["dataOrderPay", "cart", "errors", "updateDataOrderPay"]),
    minCardMonth () {
      if (this.formData.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    },
    enableButton(){
      const valid = this.formData.cardName === '' || this.formData.cardNumber === '' || this.formData.cardMonth === '' || this.formData.cardYear === ''

      return valid
    }
  },
  watch: {
    cardYear () {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = ''
      }
    }
  },
  mounted () {
    this.maskCardNumber()
    this.deleteUserTokenCard()
  },
  methods: {
    ...mapMutations("shop", ["updateUserTokenCard", "isAboutToUpdateCard", "deleteUserTokenCard"]),
    ...mapActions("shop", ["sendEpayment"]),
    generateMonthValue (n) {
      return n < 10 ? `0${n}` : n
    },
    changeName(e) {
      this.formData.cardName = e.target.value
      this.$emit('input-card-name', this.formData.cardName)
    },
    changeNumber(e) {
      this.formData.cardNumber = e.target.value
      let value = this.formData.cardNumber.replace(/\D/g, '')
      // american express, 15 digits
      if ((/^3[47]\d{0,13}$/).test(value)) {
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 17
      } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 16
      } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        this.cardNumberMaxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        let lastChar = this.formData.cardNumber.substring(this.formData.cardNumber.length, this.formData.cardNumber.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') { this.formData.cardNumber = this.formData.cardNumber.substring(0, this.formData.cardNumber.length - 1) }
      }
      this.$emit('input-card-number', this.formData.cardNumber)
    },
    changeMonth() {
      this.$emit('input-card-month', this.formData.cardMonth)
    },
    changeYear() {
      this.$emit('input-card-year', this.formData.cardYear)
    },
    // changeCvv(e) {
    //   this.formData.cardCvv = e.target.value
    //   this.$emit('input-card-cvv', this.formData.cardCvv)
    // },
    async sendCard(){
      this.isLoadingUpdate = true

      // let card = this.formData.cardNumberNotMask.replace(/ /g, '')
      // const cardIsValid = this.formData.cardMonth > this.minCardMonth
      const cardIsValid = true

      if (cardIsValid) {
        this.isAboutToUpdateCard('firsTime')

        this.updateUserTokenCard(this.formData)
        if (this.updateDataOrderPay.valid) {
          const response = await this.sendEpayment(this.updateDataOrderPay)
          if (response){
            this.resetForm()
            this.$emit('cancel-epayment-form')
            this.$emit('get-initial-content')
          }
        } else null
      } else showAlertMessage('Error', 'InfoIcon', `Invalid Card Date`, 'danger', 4000, 'bottom-right')

      this.isLoadingUpdate = false
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) this.maskCardNumber()
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber
      this.mainCardNumber = this.formData.cardNumber
      let arr = this.formData.cardNumber.split('')
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.formData.cardNumber = arr.join('')
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber
    },
    focusCardNumber() {
      this.unMaskCardNumber()
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    },
    resetForm(){
      this.formData.cardName = ''
      this.formData.cardNumber = ''
      this.formData.cardNumberNotMask = ''
      this.formData.cardMonth = ''
      this.formData.cardYear = ''
      this.mainCardNumber = ''
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/card/style.scss';
</style>