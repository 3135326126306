<template>
    <div class="main-page">

        <header class="p-header " :class="isMarholidays ? 'bg-header-two': 'bg-header-one' " >
            <nav class="nav-logo p-container">
                <img  src="@/assets/images/logo/marholidays.png" class="logo" alt="The Fives Vacations" v-if="isMarholidays"/>
                <img src="@/assets/images/logo/tfb.png" class="logo" alt="The Fives Vacations" v-else/>
            </nav>
        </header>

        <div class="body-page">
            <b-container class="content-page">
                <b-row  v-if="validPayResponse" >

                    <!-- 1ra columna -->

                    <b-col class="col-md-6 col-sm-12 col-lg-6  col-xl-5 columna">
                        <div v-if="dataOrderPay">

                            <!-- Purchased Products -->
                            <b-card tag="article" class="mb-2 rounded-0">
                                <!-- Products -->
                                <template #header>
                                    <div class="col-md-12">
                                        <h4 class="listPayTitle mb-0" style="0.75s infinite ease-in-out">
                                            Purchased Products: <strong>{{ dataOrderPay.detail.length }}</strong>
                                        </h4>
                                    </div>
                                </template>
                                <!-- Detail -->
                                <table class="table animate__animated animate__bounce">
                                    <tbody>
                                        <tr v-for="item in dataOrderPay.detail" :key="item.uuid">
                                            <td class="text-small-cart" v-if="!!item.booking" :id="'room'+item.booking.id">
                                                <span>
                                                    <span style="font-weight: bold; font-size: 16px">
                                                    {{ item.booking.roomtypename }}
                                                    </span>
                                                    <div v-if="item.balance == 0.00" class="text-center float-right">
                                                    <label><i>This booking is already paid</i></label>
                                                    </div>
                                                    <span v-else-if="item.partial==true && sum!=0">
                                                    <b-button
                                                        v-if="!item.isSelectedToPay"
                                                        variant="primary"
                                                        class="text-center float-right"
                                                        :disabled="manageEpayment"
                                                        size="sm"
                                                        @click="sendPayBook(item)"
                                                    > <feather-icon icon="CreditCardIcon"/> Pay this booking
                                                    </b-button>
                                                    <b-button
                                                        v-else
                                                        variant="danger"
                                                        class="text-center float-right"
                                                        :disabled="manageEpayment"
                                                        size="sm"
                                                        @click="cancelPayBook(item)"
                                                    > <feather-icon icon="XIcon"/> Cancel
                                                    </b-button>
                                                    </span>
                                                    <div class="text-center float-right" v-else-if="isloadingTransactions">
                                                    <b-spinner label="Loading..." small variant="success"/>
                                                    </div>

                                                    <table class="content booking" >
                                                    <tr></tr>
                                                    <tr>
                                                        <td>
                                                        <span style="font-weight: bold">Check-In:</span>
                                                        <span style="">{{ item.booking.dateIn }}</span>
                                                        </td>

                                                        <td>
                                                        <span style="font-weight: bold">Check-Out:</span>
                                                        <span style="">{{ item.booking.dateOut }}</span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                        <span style="font-weight: bold">Nights:</span>
                                                        {{ item.booking.nights }}
                                                        </td>

                                                        <td>
                                                        <span style="font-weight: bold">Adults:</span>
                                                        {{ item.booking.adults }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                        <span style="font-weight: bold">Childrens:</span>
                                                        {{ item.booking.children }}
                                                        </td>
                                                    </tr>
                                                    </table>

                                                    <table class="content booking" v-if="item.booking.dateIn">
                                                    <tr>
                                                        <td>
                                                        <span style="font-weight: bold">Plan:</span>
                                                        {{ item.booking.rateplanname }}
                                                        </td>
                                                        <td>
                                                        <span style="font-weight: bold">Balance:</span>
                                                        $ {{ item.balance }} {{ dataOrderPay.currency.code }}
                                                        </td>
                                                    </tr>
                                                    </table>
                                                </span>
                                            </td>
                                            <td v-else>
                                                <span class="">

                                                    <span class="titleProduc" style="font-weight: bold">
                                                    {{ item.itemsold }}
                                                    </span>

                                                    <span class=" display-inline" style="font-weight: bold">
                                                    Operation Date:
                                                    </span>

                                                    <span class="display-inline">
                                                    {{ item.operationdate }}
                                                    </span>

                                                </span>
                                            </td>
                                        </tr>
                                        <hr/>
                                    </tbody>
                                </table>
                                <!-- Product end -->
                            </b-card>

                            <!-- Totals -->
                            <article class="card mb-2 rounded-0">
                                <div class="card-body">
                                    <table class="table animate__animated animate__bounce" v-if="currencyValue">
                                        <tbody>
                                            <h4 class="listPayTitle">Totals</h4><hr>
                                            <tr class="text-small-cart row">
                                                <span class="col-6" style="text-align: right;margin: 0 0px 0px -11px;">
                                                    <span class="text-right">Initial Payment:</span>
                                                    $ {{(dataOrderPay.firstpay * dataOrderPay.currencyExchangeRate).toFixed(2)}}
                                                    {{ dataOrderPay.currency.code }}
                                                </span>
                                                <span class="col-6"></span>
                                            </tr>
                                            <hr/>
                                            <tr class="text-small-cart row">
                                                <span class="col-6">
                                                    <span style="font-weight: bold">Total Balance:</span>
                                                    $ {{ (dataOrderPay.totalSale * dataOrderPay.currencyExchangeRate).toFixed(2) }}
                                                    {{ dataOrderPay.currency.code }}
                                                </span>
                                                <span class="col-6">
                                                    <span  style="font-weight: bold"> Paid out:</span>
                                                    $ {{ (sum * dataOrderPay.currencyExchangeRate).toFixed(2) }}
                                                    {{ dataOrderPay.currency.code }}
                                                </span>
                                            </tr>
                                            <hr/>
                                            <tr class="total">
                                                <span>
                                                    Current / Remaining Balance :
                                                    $ {{ ((dataOrderPay.totalSale - sum) * dataOrderPay.currencyExchangeRate).toFixed(2) }}
                                                    {{ dataOrderPay.currency.code }}
                                                </span>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div v-else class="text-center" >
                                        <b-spinner label="Loading..." variant="success"/>
                                    </div>

                                </div>
                            </article>

                            <!-- Transactions -->
                            <article class="card mb-2 rounded-0" v-if="sum != 0 && !isloadingTransactions">
                            <div class="card-body">
                                <table class="table animate__animated animate__bounce">
                                <tbody>
                                    <tr >
                                    <h4 class="listPayTitle">Transactions</h4>
                                    <div>
                                    <table class="listPay">
                                        <tr>
                                        <th>Date</th>
                                        <th>Num</th> 
                                        <th>Amount</th>
                                        <!-- <th>Reference</th> -->
                                        <th>Status</th>
                                        <th>Currency</th>
                                        </tr>
                                        <tr
                                        v-for="listPay in listPayOrders"
                                        :key="listPay.id"
                                        >
                                        <td>{{listPay.date_transaction_bank ? listPay.date_transaction_bank : ""}}</td>
                                        <td>{{listPay.cc_bank}}</td>
                                        
                                        <td>$ {{ listPay.paid_amount }}</td>
                                        <!-- <td>{{listPay.folio_bank ? listPay.folio_bank : ""}}</td> -->
                                        <td>{{ listPay.transactionstatus.name }}</td>
                                        <td>{{ listPay.paid_currency.code }}</td>
                                        </tr>
                                    </table>
                                    </div>
                                </tr>
                                </tbody>
                                </table>
                            </div>
                            </article>
                            <article class="card mb-2 rounded-0" v-else-if="isloadingTransactions">
                            <div class="card-body text-center" >
                                <b-spinner label="Loading..." variant="success"/>
                            </div>
                            </article>

                            <!-- ePayment -->
                            <article class="card mb-2 rounded-0" v-if="isEpayment && formStatusCard && !isloadingTransactions">
                            <div class="card-body">
                                <h4 class="listPayTitle">ePayment</h4><hr/>
                                <b-row class="card-body">
                                <b-col md="8">
                                    <span>This transaction was made by <i>ePayment</i></span>
                                </b-col>
                                <b-col md="4" v-if="!orderAlreadyPaid">
                                    <b-button
                                    v-if="!showEditCardForm"
                                    class="text-center float-right"
                                    variant="primary"
                                    size="sm"
                                    :disabled="isPayingBooking"
                                    @click="showEpaymentForm"
                                    >
                                    <feather-icon  icon="CreditCardIcon"/> Edit card
                                    </b-button>
                                </b-col>
                                </b-row>
                                <b-card
                                tag="article"
                                class="mb-2 border rounded"
                                >
                                <b-row>
                                    <b-col class="text-center"><feather-icon size="30" icon="CreditCardIcon"/><br><label>{{cardType}}</label></b-col>
                                    <b-col>
                                    <div class="card-input text-center" style="margin-bottom:1px">
                                        <strong>Actual Card:</strong><br>
                                        <label> {{dataOrderPay.customerCard}} </label>
                                    </div>
                                    </b-col>
                                    <b-col>
                                    <div class="card-input text-center" style="margin-bottom:1px">
                                        <strong>Holder:</strong><br>
                                        <label>{{dataOrderPay.tokenpayment.cardholdername || 'N/A'}}</label>
                                    </div>
                                    </b-col>
                                </b-row>
                                </b-card>
                            </div>
                            </article>

                            <!-- Information -->
                            <article class="card mb-2 rounded-0">
                            <div class="card-header">
                                <div class="col-md-12">
                                <!-- <svg
                                    viewBox="0 0 16 16"
                                    width="1em"
                                    height="1em"
                                    focusable="false"
                                    role="img"
                                    aria-label="info circle fill"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    class="bi-info-circle-fill b-icon bi float-right"
                                >
                                    <g>
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                    ></path>
                                    </g>
                                </svg> -->
                                <h4 class="listPayTitle mb-0" style="0.75s infinite ease-in-out">
                                    Your information
                                </h4>
                                </div>
                                <!-- <h4 class="mb-0"></h4> -->
                            </div>

                            <div class="card-body">
                                <table class="table animate__animated animate__bounce">
                                <tbody>
                                    <tr class="text-small-cart">
                                    <td>
                                        <strong> Name </strong>
                                        <br />
                                        <span>
                                        {{ dataOrderPay.customer.name }}
                                        {{ dataOrderPay.customer.lastname }}
                                        </span>
                                    </td>
                                    </tr>
                                    <tr class="text-small-cart">
                                    <td>
                                        <strong> E-mail </strong>
                                        <br />
                                        <span>
                                        {{ dataOrderPay.customer.email }}
                                        </span>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </article>

                        </div>

                        <div v-else>
                        
                            <div class="alert alert-danger" role="alert">
                            No information found   <b-spinner small v-if="isValidate"></b-spinner>
                            </div>
                        </div>

                    </b-col>

                    <!-- 2da columna -->

                    <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-7 containerIframe" v-if="dataPaymentLink.url !== '' && !manageEpayment && !orderAlreadyPaid">
                        <iframe class="responsive-iframe" :src="dataPaymentLink.url"> </iframe>
                    </div>

                    <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-7" v-else-if="dataOrderPay.totalSale - sum != 0 && sum != 0 && !manageEpayment">
                        <div class="checkout-options">
                            <b-card>
                                <h3>Pay outstanding balance</h3>
                                <br />
                                <div class="row">
                                    <b-col class="col-6 col-md-6 col-sm-6">
                                        <ValidationObserver
                                            tag="form"
                                            @submit.prevent="onSubmit"
                                            ref="observer"
                                        >
                                            <ValidationProvider rules="required" name="balance">
                                            <b-input-group
                                                slot-scope="{ valid, errors }"
                                                prepend="Balance"
                                            >
                                                <b-form-input
                                                type="number"
                                                step=".01"
                                                :placeholder="parseFloat(balancePaid).toFixed(2) + ' USD'"
                                                :disabled="validPay"
                                                :state="errors[0] ? false : valid ? true : null"
                                                v-model="amount"
                                                @input="validTotal()"
                                                locale="en"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-input-group>
                                            </ValidationProvider>
                                        </ValidationObserver>
                                    </b-col>
                                    <b-col class="col-6 col-md-6 col-sm-6">
                                        <b-input-group>
                                            <b-input-group-prepend>
                                                <b-button variant="primary" disabled> 💸 Divisa </b-button>
                                            </b-input-group-prepend>
                                            <b-form-select
                                                id="divisaSelect"
                                                @change="calcularImportes"
                                                v-model="cart.cliente.currency"    
                                            >
                                                <option
                                                    v-for="cur in currencies"
                                                    :key="cur.id"
                                                    :value="cur"
                                                >
                                                    {{ cur.code }}
                                                </option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-col>
                                </div>
                                <!-- <hr> -->
                                <div class="price-details">
                                    <div v-if="cart.cliente.currency.code == 'MXN'">
                                        <div>
                                            <hr />
                                            <ul class="list-unstyled">
                                                <li class="price-detail">
                                                    <div class="detail-title detail-total"> Tipo de cambio MXN: </div>
                                                    <div class="detail-amt font-weight-bolder">
                                                        <b-badge pill>$ {{ tc }}</b-badge>
                                                    </div>
                                                </li>
                                                <li class="price-detail">
                                                    <div class="detail-title detail-total"> Total divisa MXN: </div>
                                                    <div class="detail-amt font-weight-bolder">
                                                        <b-badge pill>${{ totalDivisa }}</b-badge>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <hr />

                                    <div class="float-right" >
                                        <div v-if="!loadvalid">
                                            <b-button
                                                variant="primary"
                                                :disabled="validNull||loadvalid "
                                                @click="getLinkPay()"
                                            >
                                                <b-icon-check-square-fill></b-icon-check-square-fill> Checkout
                                            </b-button>
                                        </div>
                                        <div v-else class="text-center" >
                                            <b-spinner label="Loading..." variant="success"/>
                                        </div>
                                    </div>

                                </div>
                            </b-card>
                        </div>
                    </div>

                    <div class="col-12 col-md-7 col-sm-12" v-else-if="sum != 0 && dataOrderPay.totalSale - sum == 0">
                        <div class="checkout-options">
                            <iframe class="fixed-iframe" :src="dataPaymentLink.url"> </iframe>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-7" v-else-if="isEpayment && !formStatusCard">
                        <CardForm
                            :form-data="formData"
                            :FormStatus="waspaidwithtoken"
                            @input-card-number="updateCardNumber"
                            @input-card-name="updateCardName"
                            @input-card-month="updateCardMonth"
                            @input-card-year="updateCardYear"
                            @input-card-cvv="updateCardCvv"
                            @get-initial-content="setInitialData"
                        />
                    </div>

                    <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-7" v-else-if="manageEpayment" >
                        <CardFormEdit
                            :form-data="formData"
                            :FormStatus="waspaidwithtoken"
                            :sum="sum"
                            @input-card-number="updateCardNumber"
                            @input-card-name="updateCardName"
                            @input-card-month="updateCardMonth"
                            @input-card-year="updateCardYear"
                            @input-card-cvv="updateCardCvv"
                            @get-initial-content="setInitialData"
                            @cancel-epayment-form="hideEpaymentForm"
                        />
                    </div>

                    <div v-else class="col-12 col-md-7 col-sm-12">
                        <div class="checkout-options" v-if="validNull==false">
                            <b-card>
                                <div class="w-100 text-center">
                                    <h2 class="mb-1">This order was not paid 🕵🏻‍♀️</h2>
                                    <img
                                        src="/img/error.7cce96d0.svg"
                                        alt="Error page"
                                        class="img-fluid"
                                    />
                                </div>
                            </b-card>
                        </div>
                        <div v-else class="text-center loadingInitiate" >
                            <b-spinner label="Loading..." variant="success"/>
                        </div>
                    </div>

                </b-row>

                <b-col  class="col-md-6 col-sm-12 col-lg-6 col-xl-5 columna mx-auto text-center" v-if="validPayResponse==false">
                    <b-card>
                        <b-col md="12">
                            <ValidationProvider name="email" rules="required|email">
                                <b-form-group label="Order Customer email*" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="email"
                                        v-model="email"                                    
                                        autocomplete="off"
                                        :state="errors[0] ? false : valid ? true : email != '' ? true : false"
                                    />                                
                                    <b-form-invalid-feedback> {{ (errors[0] !== '' ? 'Please enter a valid email' : '') }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>

                            <b-button
                                            
                                variant="primary"
                                class="text-center float-right"
                                :disabled="manageEpayment"
                                size="sm"
                                @click="setInitialData()"   
                            >
                                <b-spinner small v-if="isValidate" />
                                Continue
                            </b-button> 
                        </b-col>
                    </b-card>
                </b-col>               
            </b-container>
        </div>

        <footer class="p-footer  " :class="isMarholidays ? 'p-footer-bg-two': 'p-footer-bg-one' ">

            <div class="footer-1 " v-if="isMarholidays">

                <div class=" text-center mt-1" style="font-weight: bold; color:white; font-weight:normal;" id=""> 
                        <span style="font-weight:normal;">© MAR HOLIDAYS TRAVEL NETWORK, A COMPANY OF MAR HOLIDAYS TRAVEL D.M.C.</span> <br>
                        <span>El Limonar 1, Xcalacoco 77710 Playa del Carmen, Quintana Roo.</span>                                              
                </div>
            </div>

            <div class="footer-2" v-else>
                
                <div class=" text-center mt-1" style="font-weight: bold; color:white; font-weight:normal;" id=""> 
                    <span style="font-weight:normal;">The Fives Hotels & Residences</span> <br>
                    <span>El Limonar 1, Xcalacoco 77710 Playa del Carmen, Quintana Roo.</span>                                       
                </div>
                
            </div>

            
        </footer>
    </div>
</template>

<script>

import { toJson,  showAlertMessage, } from "@/helpers/helpers"
import { mapState, mapActions, mapMutations } from "vuex"
import CardForm from "@/modules/shop/components/landing/CardForm"
import CardFormEdit from "@/modules/shop/components/landing/CardFormEdit"


export default {
    components: {
        CardForm,
        CardFormEdit
    },
    async mounted(){

        this.getUrl()
        this.checkDomain()

        if (this.order && this.email){      
            this.setInitialData()
        } else {
            this.validPayResponse = false
            this.setDataOrder(null)
        }
    },
    data() {
        return {
            domain: null,
            isMarholidays: false,           
            loadvalid:false,
            order: this.$route.query.order,
            urlbank: this.dataPaymentLink,
            sum: 0,
            amount: "",
            validNull: null,
            validPayResponse:false,
            currencyValue: 0,
            listPayOrders: [],
            balancePaid:0.00,
            balanceId:0,
            isLoadingIframe: false,
            validPay: true,
            formData: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: ''
            },
            labelTextValidEmail:"",
            email:this.$route.query.email,
            waspaidwithtoken: false,
            showEditCardForm: false,
            isPayingBooking: false,
            isloadingTransactions: false,
            isValidate:false,
        }
    },
    computed: {
        ...mapState("shop", ["cart"]),
        ...mapState("start", ["currencies"]),
        ...mapState("shop", ["errorMessageApi", "dataOrderPay"]),
        ...mapState("mySales", ["errorMessageApi", "dataTransaction","isloadingProcess","dataPaymentLink","transactionPay"]),

        subTotal() {
            return (Math.round((parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.cart.totalBreakdown.total)) * 100) / 100).toFixed(2)
        },
        total() {
            return (Math.round(this.amount * 100) / 100).toFixed(2);
        },
        tc() {
            const currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1
            return (Math.round(currencieValor * 100) / 100).toFixed(2);
        },
        totalDivisa() {
            var amount = this.amount
            if (amount == "") amount = 0
            else amount = this.amount

            const currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1
            return (Math.round(parseFloat(amount) * parseFloat(currencieValor) * 100) / 100).toFixed(2)
        },
        formStatusCard(){
            const valid = this.dataOrderPay.tokenpayment ? true : false // valida real
            // const valid = this.dataOrderPay.tokenpayment ? false : true
            this.waspaidwithtoken = valid
            return valid
        },
        isEpayment(){
            const validepayment = this.dataOrderPay?.isEpayment
            const validpaymentmethod = this.dataOrderPay?.paymentmethod?.ePayment
            const valid = validepayment == true && validpaymentmethod == true
            return valid == true
        },
        manageEpayment(){
            if (this.isEpayment) {
                if (this.waspaidwithtoken) if (!this.showEditCardForm) return false
                return true
            } else return false
        },
        cardType(){
            if (this.dataOrderPay?.tokenpayment?.cardtype == 1) return 'Visa'
            if (this.dataOrderPay?.tokenpayment?.cardtype == 2) return 'American Express'
            if (this.dataOrderPay?.tokenpayment?.cardtype == 3) return 'Mastercard'
        },
        orderAlreadyPaid(){
            return ((this.dataOrderPay?.totalSale - this.sum) * this.currencyValue?.value).toFixed(2) == 0.00
        }

    },
    methods: {
        ...mapActions("shop", ["fetchDataOrder","sendValidatePOS"]),
        ...mapActions("mySales", ["fetchDataurlBank"]),
        ...mapActions("mySales", ["fetchDataTransaction"]),

        ...mapActions("mySales", ["fetchTransactionPay"]),
        ...mapActions("start", ["fetchCurrencies"]),

        ...mapMutations("shop", ["setDataOrder","setCurrency"]),
        ...mapMutations("mySales", ["setDataTransaction",]),
        ...mapMutations('start',['setCurrencies']),
    

        getUrl(){
            // Obtén el dominio y la ruta completa de Vue Router
            const domain = window.location.origin;
            const fullPath = this.$router.currentRoute.fullPath;

            // Combina el dominio y la ruta completa para obtener la URL completa
            this.domain = domain + fullPath;

            return this.domain            
        },
        checkDomain(){
            const keyWord = 'marholidaystravel.com'
            

            if( this.domain && this.domain.includes( keyWord ) ){
                this.isMarholidays = true   
                this.changeHeadersPage()
                // favicon-marholiday.ico
            }

        },
        changeHeadersPage(){
            document.title = 'Mar Holidays Travel Network'
                        
            const favicon = document.createElement('link')
            favicon.rel = 'icon'
            favicon.href = 'favicon-marholiday.ico' 

            // Obtener el elemento head y reemplazar cualquier favicon existente
            const head = document.head || document.getElementsByTagName('head')[0];
            const favicons = head.querySelectorAll('link[rel="icon"]');
            favicons.forEach((faviconElement) => {
                head.removeChild(faviconElement);
            });

            // Agregar el nuevo favicon al head
            head.appendChild(favicon);

        },
        async setInitialData(){
            const payload = {
                "order":this.order,
                "email":this.email
            }

            this.isValidate = true
            const validProcess= await this.sendValidatePOS(payload)
   
            if(validProcess == true ) {
                this.validPayResponse=true
                await this.getDataOrder()
                this.getDaSum()
                if( !this.currencies.length ){
                    const currencies = await this.fetchCurrencies()  
                    this.setCurrencies(currencies) //muto state currencies en start
                }
                
                this.setCurrencyCustomer()

                this.currencyValue = this.currencies.find((currency) => currency.code === this.dataOrderPay.currency.code)
            } else {
                this.labelTextValidEmail="Customer email does not match"
                showAlertMessage( 'Email validation', 'InfoIcon', this.labelTextValidEmail, 'warning', 4000, 'bottom-right')
                this.isValidate = false
                this.validPayResponse = false
                this.setDataOrder(null)
            }
        },
        setCurrencyCustomer(){
            const cliente = this.cart.cliente
            const { currency } = cliente
            const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )    
            if( !currency ) this.setCurrency( divisa )
        },
        setNullDataOrder() {
            this.setDataOrder(null);
        },
        async getDataOrder() {

            await this.fetchDataOrder(this.order)
            this.isloadingTransactions = true
            await this.fetchDataTransaction(this.order)
            this.isloadingTransactions = false

            await this.fetchDataurlBank(this.order)
        },
        getDaSum() {
            this.listPayOrders = []
            this.dataTransaction.forEach((list) => {
                if (list.transactionstatus.name == "Approved") this.sum += parseFloat(list.amount)
                if (list.transactionstatus.name != "Pending") this.listPayOrders.push(list)
            })
            if(this.sum != 0 && this.dataOrderPay.totalSale - this.sum != 0) this.dataPaymentLink.url = ''
        },
        async getLinkPay() {
            const payload = { amount: this.amount, order: this.order,detail:this.balanceId };
            
            this.loadvalid=true
            await this.fetchTransactionPay(payload)
            this.loadvalid=false
        // this.urlbank = this.dataPaymentLink.url;
        },
        calcularImportes() {
            const currencieValor = this.cart?.cliente?.currency ? this.cart.cliente.currency.value : 1
            this.divisaTotal = (Math.round(parseFloat(this.cart.totalBreakdown.total) * parseFloat(currencieValor) * 100) / 100).toFixed(2)
            this.setCurrency(this.cart.cliente.currency)
        },
        validTotal() {
            const total = parseFloat(this.amount);
            const balance = this.dataOrderPay.totalSale - this.sum
            if (total <= balance) this.validNull = false
            else this.validNull = true
        },
        sendPayBook(item){
            if(!!item?.booking?.id && !!item?.partial && this.sum != 0 ){
                var activeClass = document.querySelector(".bg-gradient-success")
                if(activeClass!=null) activeClass.classList.remove("bg-gradient-success")

                const isChecked = document.getElementById("room"+item?.booking?.id)
                isChecked.classList.add('bg-gradient-success')

                this.isPayingBooking=true
                this.balancePaid=item.balance
                this.balanceId=item.id
                this.validPay=false
                this.validNull=false
                this.amount=item.balance
                item.isSelectedToPay = true
            }
        },
        cancelPayBook(item){
            const isChecked = document.getElementById("room"+item?.booking?.id)
            isChecked.classList.remove("bg-gradient-success")

            this.isPayingBooking=false
            this.balancePaid=0.00
            this.balanceId=0
            this.validPay=true
            this.validNull=true
            this.amount=""
            item.isSelectedToPay = false
        },
    // paycard
        updateCardNumber (val) {
        },
        updateCardName (val) {
        },
        updateCardMonth (val) {
        },
        updateCardYear (val) {
        },
        updateCardCvv (val) {
        },
        showEpaymentForm(){
            this.showEditCardForm = true
        },
        hideEpaymentForm(){
            this.showEditCardForm = false
        }
    }
}

</script>

<style scoped>

.main-page {
    overflow-x: hidden;
    min-height: 100vh; /* Asegura que el contenedor tenga al menos la altura del viewport */  
}

.p-container {
    max-width: 968px;
    margin-left: 1rem;
    margin-right: 1rem;    
}

.p-header {
    width: 100%;
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 100;    
    text-align: center; /* Alinea el contenido al centro horizontalmente */
    padding: 1rem;
}

.bg-header-one {
    background-color: #000000;    
}

.bg-header-two {
    background-color: #FFFFFF;
}

.p-container .nav{
    text-align: center; /* Alinea el contenido al centro horizontalmente */
}

.p-header nav {
    height: 7rem;
    text-align: center;
}

.nav-logo {  
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  display: flex;
  justify-content: center; /* Alinea el contenido del contenedor al centro horizontalmente */
  align-items: center; /* Alinea el contenido del contenedor al centro verticalmente */

}

.nav-logo img {
    width: 200px;
}


.body-page{
    /* margin-top: 10rem;  */
    /* height: 100vh; */
    margin-bottom: 3rem; 

}
.content-page{
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    padding: 1rem;
}

.p-footer {
    
    background-color: #000000;    
    color: white;
    /* text-align: center; */
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: .5rem;
    z-index: 1000;
}

.p-footer-bg-one{
    background-color: #000000;    
}


.p-footer-bg-two{
    background-color: #45C3BB;    
}

.footer-1{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.footer-1 img {
    width: 250px;
}


p a {
    color: #FFFFFF;
}



/* Rest css */
iframe {
  overflow: hidden;
}

.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 619px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.fixed-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 40%;
  border: none;
}

.alert.alert-danger {
  padding: 9px 0 7px 13px;
}

.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1440px;
}



.container {
  padding-bottom: 62px;
}


#app > div {
  display: inline-table;
}

tr.total {
  text-align: center;
  padding: 14px 0 0 0;
  display: block;
  font-weight: 600;
  font-size: 18px;
}

tr.text-small-cart.row {
  text-align: center;
}



tr.listPay {
  text-align: center;
  font-size: 12px;
}

.listPay th, .listPay td {
    padding: 8px;
    font-size: 12px;
    vertical-align: middle;
}
.listPayTitle {
  text-align: center;
  margin-bottom: 10px;
}
ul.list-unstyled {
  text-align: right;
}
table.content.booking {
  margin: 12px 0 0 0;
}

table.content.booking span {
  padding-right: 5px;
}
table.content.booking tr,
table.content.booking td {
  border: 0;
  padding: 6px 15px 6px 15px;
}

.titleProduc {
  font-weight: bold;
  margin: 3px 0 10px 0;
  display: block;
}

table.listPay {
  margin: 0 auto;
}



.bg-gradient-success, .btn-gradient-success {
    color: #fff;
    transition: all 0.2s ease;
    background-image: linear-gradient( 47deg, #339e62, #1f8a4e);
    background-repeat: repeat-x;
    background-repeat: repeat;
}

.loadingInitiate {
    margin: 20px 0 0 0;
}

@media (max-width: 1380px) {
.listPay th, .listPay td {
    padding: 5px;
    font-size: 10px;
    vertical-align: middle;
}


 }
 footer.navbar-dark {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1;
}
</style>
<style scoped src="@/assets/css/success.css"></style>
